// Libs
import moment, { Moment } from 'moment';

// Hooks
import {
  // useAssignDistrictLeadership,
  // useDeleteDistrictLeadership,
  // useUpdateDistrictLeadership,
  useReplaceAgClubAffiliations,
} from '@repositories/districts';

import { useAssignRoleToMember } from '@domui-hooks/useAssignRoleToMember';
import { useReplaceOfficer } from '@domui-hooks/useReplaceOfficer';
import { useRemoveFromAssignedRole } from '@domui-hooks/useRemoveFromAssignedRole';
import { getEndRYYear } from '@domui-utils/datetime';

type Props = {
  districtId: string;
  riDistrictId?: number | null;
};

export const useManageDistrictLeadership = ({
  districtId,
}: // riDistrictId,
  Props) => {
  const {
    loading: assignRoleToMemberLoading,
    error: assignRoleToMemberError,
    assignRoleToMember,
  } = useAssignRoleToMember();

  const {
    loading: replaceOfficerLoading,
    error: replaceOfficerError,
    replaceOfficer,
  } = useReplaceOfficer();

  const {
    loading: removeOfficerLoading,
    error: removeFromAssignedRoleError,
    removeOfficer,
  } = useRemoveFromAssignedRole();

  const [
    replaceAgClubAffiliations,
    { loading: replaceAgClubAffiliationsLoading },
  ] = useReplaceAgClubAffiliations();

  const isLoading =
    assignRoleToMemberLoading ||
    replaceOfficerLoading ||
    removeOfficerLoading ||
    replaceAgClubAffiliationsLoading;

  const error =
    assignRoleToMemberError ||
    replaceOfficerError ||
    removeFromAssignedRoleError;

  const dateFormat = 'YYYY-MM-DD';

  const updateDLForCurrentRY = async (
    leadershipId: string,
    newIndividualId: string,
    dateToRemove: Date | Moment
  ) => {
    return await replaceOfficer({
      leadershipId,
      individualId: newIndividualId,
      date: moment(dateToRemove).format(dateFormat),
    });
  };

  const updateDLForFutureRY = async (
    leadershipId: string,
    dateToRemove: Date | Moment
  ) =>
    removeOfficer({
      leadershipId,
      terminationDate: moment(dateToRemove).format(dateFormat),
      clubId: districtId,
    });

  const replaceClubAffiliationsForFutureRY = async (
    individualId: string,
    newIndividualId: string,
    startDate: string
  ) =>
    replaceAgClubAffiliations({
      variables: {
        districtId,
        individualId,
        newIndividualId,
        startDate,
      },
    });

  const assignDL = async (
    individualId: string,
    roleId: string,
    assignDate: Date | string | Moment
  ) => {
    const formattedDate = moment(assignDate).format(dateFormat);
    const endDate = `${getEndRYYear(formattedDate)}-06-30`;

    return await assignRoleToMember({
      individualPk: individualId,
      organizationPk: districtId,
      rolePk: roleId,
      startDate: formattedDate,
      endDate,
    });
  };

  return {
    assignDL,
    updateDLForFutureRY,
    updateDLForCurrentRY,
    replaceClubAffiliationsForFutureRY,
    isLoading,
    assignRoleToMemberError,
    replaceOfficerError,
    removeFromAssignedRoleError,
    error,
  };
};
