import React, { useState } from 'react';

import { Formik } from 'formik';
import { isEmpty, isEqual } from 'lodash';

import { Button } from '@components/Button';
import { formatDateOfBirth } from '@components/Formik/DateOfBirth/utils';
import { FormElementErrorMessage } from '@components/Forms/FormElement';
import Loading from '@components/Loading';
import Title from '@components/Title';

import IndividualDetailsForm from './IndividualDetailsForm';
import IndividualDetailsValidationSchema from './IndividualDetailsValidationSchema';

import {
  mapMemberDetailsInputToValues,
  MemberDetailsFormValues,
  MemberDetailsInput,
  MemberDetailsOutput,
} from '@domui-domain/clubs';
import { isDES } from '@domui-domain/districts';

import { useTouchedFormFields } from '@domui-use-cases/clubs';
import { useErrorHandling } from '@use-cases/notifications';

import { isValidEmail } from '@utils/validation';

import { useTranslation } from '@external/react-i18next';
import { useAddMembersSearch } from '@domui-hooks/useAddMembersSearch';

export interface MemberDetailsProps {
  /**
   * Predefined member data to load into the form.
   */
  data: MemberDetailsInput;
  initialData: MemberDetailsInput;
  /**
   * Callback to return the reviewed or created member metadata.
   * @param data
   */
  submitHandler: (
    formData: MemberDetailsInput,
    dataToUpdate: MemberDetailsOutput
  ) => void;

  handleFormFieldsTouched?: (isTouched: boolean) => void;
  roleName?: string;
  selectedPreviousMember?: boolean;
}

const IndividualDetails: React.FC<MemberDetailsProps> = ({
  data,
  initialData,
  submitHandler,
  children,
  handleFormFieldsTouched,
  roleName,
  selectedPreviousMember,
}) => {
  const { t } = useTranslation();
  const { checkTouchedFields } = useTouchedFormFields(handleFormFieldsTouched);

  const { error: errorSearch, addMembersSearch } = useAddMembersSearch();
  const [emailMessage, setEmailMessage] = useState('');
  useErrorHandling(
    errorSearch?.message,
    !!errorSearch,
    'search-individual.error'
  );

  const submit = (formValues: MemberDetailsFormValues) => {
    submitHandler(formValues, mapMemberDetailsInputToValues(formValues));
  };

  if (!data) {
    return <Loading />;
  }

  let prevFormValues: MemberDetailsFormValues;

  if (!isEqual(data, initialData)) {
    prevFormValues = {
      ...initialData,
      ...formatDateOfBirth(initialData.dateOfBirth, initialData.yearOfBirth),
    };
  }

  const initialValues: MemberDetailsFormValues = {
    ...data,
    ...formatDateOfBirth(data.dateOfBirth, data.yearOfBirth),
  };

  let emailvalue = '';
  const PAGE_SIZE = 5;
  const currentPage = 1;

  const handleBlurEmailHandler = async () => {
    if (isEmpty(emailvalue)) {
      setEmailMessage(t('form.field.error_required', 'Required'));
    } else if (!isValidEmail(emailvalue)) {
      setEmailMessage(
        t('form.field.error_invalid_email', 'Invalid email address')
      );
    } else if (!isEqual(initialValues.email, emailvalue)) {
      const searchIndividualData = await addMembersSearch(
        currentPage,
        PAGE_SIZE,
        {
          email: emailvalue,
        }
      );
      const individualData = searchIndividualData?.totalCount || 0;

      individualData > 0
        ? setEmailMessage(
            t(
              'dis-validation-error.email-already-exists',
              'The email already exists in the system.'
            )
          )
        : setEmailMessage('');
    } else setEmailMessage('');
  };

  const getTitle = () => {
    if (selectedPreviousMember && isDES(roleName)) {
      return t('confirm-officer.des.add_form.title', 'Confirm {{name}}', {
        name: roleName,
      });
    }
    if (isDES(roleName)) {
      return t(
        'add-new-officer-assign.des.add_form.title',
        'Add New {{name}}',
        { name: roleName }
      );
    }
    return t('add_member.add_member_form.title', 'Enter Member Details');
  };

  return (
    <>
      <Title className="h2 mt-4 mb-2">{getTitle()}</Title>
      <div className="tablet:w-1/2 desktop:w-2/5">
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validateOnBlur
          validationSchema={IndividualDetailsValidationSchema(t)}
        >
          {({ values, isValid, submitCount, handleSubmit, dirty }) => {
            checkTouchedFields(prevFormValues || initialValues, values);
            emailvalue = values.email || '';
            const isEmailValid = Boolean(emailMessage.length < 1);
            const isPrimaryPhone = Boolean(
              values.primaryPhone?.countryId && values.primaryPhone?.number
            );

            return (
              <>
                <IndividualDetailsForm
                  values={values}
                  handleBlurEmailHandler={handleBlurEmailHandler}
                  emailMessage={emailMessage}
                />
                <p className="mb-12">
                  {t(
                    'add_member.add_member_form.legal_description',
                    `Your privacy and that of club members is important to Rotary and the personal data you share with Rotary will only be used for core business purposes. These purposes include financial processing, supporting The Rotary Foundation, facilitating event planning, communicating key organizational messages and responding to your inquiries. Rotary’s privacy policy can be found at <a target="_blank" href="https://my.rotary.org/en/privacy-policy">my.rotary.org/privacy-policy</a>. Questions about this policy can be directed to <a href="mailto:privacy@rotary.org">privacy@rotary.org</a>`
                  )}
                </p>
                {!isValid && !!submitCount && (
                  <div className="mb-4">
                    <FormElementErrorMessage id="global">
                      {t(
                        'add_member.details_form.errors',
                        'The form contains errors. Please review and correct them first.'
                      )}
                    </FormElementErrorMessage>
                  </div>
                )}
                <Button
                  full
                  type="submit"
                  className="mb-8"
                  clickHandler={handleSubmit}
                  disabled={
                    (!isValid && dirty) || !isPrimaryPhone || !isEmailValid
                  }
                >
                  {t('continue', 'Continue')}
                </Button>
              </>
            );
          }}
        </Formik>
        {children}
      </div>
    </>
  );
};

export default IndividualDetails;
